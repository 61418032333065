import { createStore } from 'vuex'
import auth from '@/store/modules/auth'
import axios from '@/http'
import router from '@/router'

export default createStore({
	state: () => ({ orderId: null }),

	actions: {
		async initialAction({ dispatch }) {
			await dispatch('checkAuth')
		},

		async checkAuth({ commit }) {
			try {
				const authData = await axios.get('/auth/verify')
				const userRoleIds = await axios.get('/auth/getUserRoleIds')

				commit('auth/loginCommit', true)
				commit('auth/setAuthData', authData.data)
				commit('auth/setUserRoleIds', userRoleIds.data)
			} catch (e) {
				router.push('/login')
			}
		}
	},

	mutations: {
		setOrderId(state, id) {
			state.orderId = id
		}
	},

	modules: {
		auth
	}
})
