import type { StoreOptions } from 'vuex'
import axios from '@/http'

export default {
	namespaced: true,

	state: () => ({
		isAuth: false,
		authData: null,
		userRoleIds: []
	}),

	mutations: {
		loginCommit(state, bool: boolean) {
			state.isAuth = bool
		},

		setAuthData(state, authData) {
			state.authData = authData
		},

		setUserRoleIds(state, userRoleIds) {
			state.userRoleIds = userRoleIds
		}
	},

	actions: {
		async loginAction({ commit }, data) {
			try {
				const authData = await axios.post('/auth/validate', data)
				const userRoleIds = await axios.get('/auth/getUserRoleIds')

				commit('setAuthData', authData.data)
				commit('setUserRoleIds', userRoleIds.data)
				commit('loginCommit', true)
			} catch (e) {}
		},

		async logout() {
			await axios.get('/auth/logout')

			window.location.replace('/login')
		}
	}
	// eslint-disable-next-line
} as StoreOptions<any>
