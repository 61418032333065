
import { defineComponent, provide } from 'vue'

import { shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'

export default defineComponent({
	name: 'AppLayout',

	setup() {
		const layout = shallowRef()
		const route = useRoute()

		const checkRole = (bool: boolean) => {
			if (!bool) {
				ElMessage.error('Недостаточно прав.')
				throw 'Недостаточно прав.'
			}
		}

		provide('checkRole', checkRole)

		watch(
			() => route.meta,
			async meta => {
				if (meta.layout) {
					return (layout.value = (await import(`@/layouts/${meta.layout}.vue`)).default)
				}

				layout.value = (await import(`@/layouts/DefaultLayout.vue`)).default
			}
		)

		return { layout }
	}
})
