import { createApp } from 'vue'
import AppLayout from '@/layouts/AppLayout.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const main = async () => {
	await store.dispatch('initialAction')

	createApp(AppLayout).use(store).use(router).use(ElementPlus).mount('#app')
}

main()
