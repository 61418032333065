import Axios, { AxiosRequestConfig } from 'axios'
import store from '@/store'
import { Cache } from 'axios-extensions'
import cacheAdapter from '@/http/cacheAdapter'

const URL = process.env.VUE_APP_URL_WAREHOUSE_API

const axios = Axios.create({
	baseURL: URL,
	withCredentials: true
})

const defaults = axios.defaults
if (defaults.adapter) {
	const FIVE_MINUTES = 1000 * 60 * 5
	const axCache = new Cache({ maxAge: FIVE_MINUTES, max: 10 })

	defaults.adapter = cacheAdapter(defaults.adapter, {
		enabledByDefault: false,
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		defaultCache: axCache
	})
}

axios.interceptors.request.use(
	async config => {
		if (store.state.auth.authData?.accessToken) {
			config.headers = config.headers || {}
			config.headers.Authorization = `Bearer ${store.state.auth.authData.accessToken}`
		}

		return config
	},
	error => Promise.reject(error)
)

let retry = false

axios.interceptors.response.use(
	response => {
		if (response.config.url === '/auth/refresh') retry = false

		return response
	},
	async error => {
		const config = error.config

		if (error.response.status === 401 && !retry) {
			retry = true

			try {
				const authData = await axios.get('/auth/refresh')
				const userRoleIds = await axios.get('/auth/getUserRoleIds')

				store.commit('auth/setAuthData', authData.data)
				store.commit('auth/setUserRoleIds', userRoleIds.data)

				const newGfgReq: AxiosRequestConfig = {
					baseURL: URL,
					data: config.data,
					headers: config.headers,
					url: config.url,
					method: config.method
				}

				return axios(newGfgReq)
			} catch (e) {
				store.dispatch('auth/logout')
			}
		}

		return Promise.reject(error)
	}
)

export default axios
